// importa o plugin de Request para fazer as requisições para o back
import Request from '@/plugins/request'
// import { filter } from 'vue/types/umd'

// eporta o store de crud
export default {
	// estado do store de crud
	state: {
		// status do store de crud
		status: null,
		// registro dos crud
		paginacao: null,
		// registro dos crud
		dados_get: null
	},
	// funções para modificar o estado do store de crud
	mutations: {
		// função para setar o status do store de crud
		SET_STATUS(state, status) {
			// seta o status do store de crud
			state.status = status
		},
		// função para setar os registros de crud
		SET_GET_PAGINACAO(state, paginacao) {
			// seta os registros de crud
			state.paginacao = {
				// seta os registros de crud
				dados: paginacao.dados,
				// seta o total registros de crud
				total: paginacao.total || paginacao.dados.length 
			}
		},
		SET_GET_CRUD(state,dados){
			state.dados_get = dados
		}
	},
	// funções para as ações do store de crud
	actions: {
		/**
		 * função para coletar os registros dos crud do back
		 * @param {*} param0 
		 * @param {*} filters 
		 */
		async getCrudDinamicoRead({ commit }, filters) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/crud_dinamico/get_paginacao?crud=' + filters.adicionais.crud +
                    (filters.adicionais.campos ? '&campos=' + JSON.stringify(filters.adicionais.campos) : '') +
                    (filters.adicionais.coluna_id ? '&coluna_id=' +filters.adicionais.coluna_id : '') +
					// quantos registros devem ser pulados
					(filters.offset ? '&offset=' + filters.offset : '') +
					// quantos registros devem ser retornados
					(filters.limit ? '&limit=' + filters.limit : '') +
					// por qual coluna devem ser ordenados os registros
					(filters.order_column ? '&order_column=' + filters.order_column : '&order_column=nome') +
					// qual a ordem que deve ser ordenado (ASC ou DESC)
					(filters.order_dir ? '&order_dir=' + filters.order_dir : '&order_column=asc') +
					// os registros que contem a busca solicitada
					(filters.search ? '&search=' + filters.search : ''), 
			})
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// caso o status seja 200 (requisiçao foi concluida) 
			if (resp.status == 200) {
				// seta os dados no store
				await commit('SET_GET_PAGINACAO', resp.data)
			}
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para coletar apenas 1 usuário do back
		 * @param {*} param0 
		 * @param {*} codigo 
		 */
		async getCrudDinamico({ commit }, dados) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/crud_dinamico/get_crud_dinamico/?codigo=' + dados.codigo + '&coluna_id=' + dados.coluna_id+ '&crud=' + dados.crud + '&campos=' + dados.campos, 
			})
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')

            // seta os dados no store
            await commit('SET_GET_CRUD', resp.data.dados)
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para coletar apenas 1 usuário do back
		 * @param {*} param0 
		 * @param {*} usuario_id 
		 */
		async getSelectCrudDinamico({ commit }, dados) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: (dados.tipo == 'crud'? 'admin/crud_dinamico/get_select_crud_dinamico/' : dados.api) + '?campos=' + JSON.stringify(dados), 
			})
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')

            // seta os dados no store
            await commit('SET_USUARIOS', resp.data)
			// retorna a resposta da requisição
			return resp
		},
		
		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 * @param {*} dados_crud_dinamico 
		 */
		async postCrudDinamico({ commit }, dados_crud_dinamico) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/crud_dinamico/post_crud_dinamico/',
				// corpo da requisição
				body: dados_crud_dinamico, 
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
	},
	// funções para coletar o estado do store de crud
	getters: {
		// coleta o status do store de crud
		status_crud_dinamico: state => state.status,
		// coleta os registros do store de crud
		paginacao_crud_dinamico: state => state.paginacao,
		dados_get_crud_dinamico: state => state.dados_get,
	}
}