// importa o plugin de Request para fazer as requisições para o back
import Request from '@/plugins/request'

// eporta o store de usuários
export default {
	// estado do store de usuários
	state: {
		// status do store de usuários
		status: null,
		// registro dos usuários
		usuarios: null,
		menus_usuario:[]
	},
	// funções para modificar o estado do store de usuários
	mutations: {
		// função para setar o status do store de usuários
		SET_STATUS(state, status) {
			// seta o status do store de usuários
			state.status = status
		},
		// função para setar os registros de usuários
		SET_USUARIOS(state, usuarios) {
			// seta os registros de usuários
			state.usuarios = {
				// seta os registros de usuários
				dados: usuarios.dados.data,
				// seta o total registros de usuários
				total: usuarios.dados.total
			}
		},
		// função para setar os registros de usuários
		SET_MENUS(state, menus_usuario) {
			// seta os registros de usuários
			state.menus_usuario = menus_usuario
		},
	},
	// funções para as ações do store de usuários
	actions: {
		/**
		 * função para coletar os registros dos usuários do back
		 * @param {*} param0 
		 * @param {*} filters 
		 */
		async getUsuarios({ commit }, filters) {
			// seta o status do store de usuarios para loading

			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/usuario/get_usuarios?' +
					// quantos registros devem ser pulados
					(filters.offset ? '&offset=' + filters.offset : '') +
					// quantos registros devem ser retornados
					(filters.limit ? '&limit=' + filters.limit : '') +
					// por qual coluna devem ser ordenados os registros
					(filters.order_column ? '&order_column=' + filters.order_column : '&order_column=nome') +
					// qual a ordem que deve ser ordenado (ASC ou DESC)
					(filters.order_dir ? '&order_dir=' + filters.order_dir : '&order_column=asc') +
					// os registros que contem a busca solicitada
					(filters.search ? '&search=' + filters.search : '') + 
					// os registros que contem a busca solicitada
					(filters.currentPage ? '&page=' + filters.currentPage : ''), 
			})
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// caso o status seja 200 (requisiçao foi concluida) 
			if (resp.status == 200) {
				// seta os dados no store
				await commit('SET_USUARIOS', resp.data)
			}
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para coletar apenas 1 usuário do back
		 * @param {*} param0 
		 * @param {*} usuario_id 
		 */
		async getUsuario({ commit }, usuario_id) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/usuario/get_usuario/?codigo=' + usuario_id, 
			})
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para coletar apenas 1 usuário do back
		 * @param {*} param0 
		 * @param {*} usuario_id 
		 */
		async getCupons({ commit }, dados) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/usuario/get_cupons/?empresa_id=' + dados.empresa_id + '&usuario_id=' + dados.codigo_usuario + '&valor_pedido=' + dados.sub_total,
			})
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
		
		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 * @param {*} dados_usuario 
		 */
		async postUsuarios({ commit }, dados_usuario) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/usuario/post_usuario/',
				// corpo da requisição
				body: dados_usuario, 
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
		/**
		 * função para buscar menu que o usuario pode ver
		 * @param {*} param0 
		 */
		async getMenuByUsuario({ commit }) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/usuario/getMenus/',
			})
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp.status == '200' ? resp.data.menus : []
		},
		
		/**
		 * função para buscar menu que o usuario pode ver
		 * @param {*} param0 
		 */
		async verificaMenuByUsuario({ commit },menu) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/usuario/verificaMenu/',
				// corpo da requisição
				body: {menu:menu}, 
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp.status == '200' ? resp.data.sucesso : false
		},	

		/**
		 * função para buscar menu que o usuario pode ver
		 * @param {*} param0 
		 */
		async postUsuarioIdOneSignal({ commit },dados) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/usuario/postUsuarioChaveNotificacao/',
				// corpo da requisição
				body: dados, 
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp.status == '200' ? resp.data.sucesso : false
		},
	},
	// funções para coletar o estado do store de usuários
	getters: {
		// coleta o status do store de usuários
		status_usuarios: state => state.status,
		// coleta os registros do store de usuários
		usuarios: state => state.usuarios,
	}
}