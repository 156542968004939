// importa o plugin de Request para fazer as requisições para o back
import Request from '@/plugins/request'
// importa o jwt para decodificação do token
// import jwt_decode from "jwt-decode"

export default {
	state: {
		status: null,
        estados: [],
        cidades: [],
				clientes: {
					menus: [],
					usuario: {}
				}
	},
	mutations: {
		SET_STATUS(state, status) {
			state.status = status
		},
		SET_CIDADE(state, cidades) {
			state.cidades = cidades
		},
		SET_ESTADO(state, estados) {
			state.estados = estados
		},
		SET_NOVO_CLIENTE(state, clientes){
				state.clientes = clientes
		},
		SET_PEDIDO(state, pedidos){
			state.pedidos = pedidos
		},
		SET_USER(state, user) {
			state.user = user
		},
		SET_ENDERECO(state, endereco) {
			state.clientes.usuario.enderecos = endereco
		},
		SET_MENUS_USUARIO(state, menus_usuario) {
			state.menus_usuario = menus_usuario
		},
		SET_RECOVER_EMAIL(state, email) {
			state.recorever_email = email
		},
		LOGOUT(state) {
			state.status = null
			state.token = null
			state.user = null
		},
	},
	actions: {
		async getCidade({ commit }, codigo_estado) {
			await commit('SET_STATUS', 'loading')
			if(codigo_estado){
				const resp = await Request({
					route: 'api/get_cidade?codigo_estado=' + (codigo_estado ? codigo_estado : null) + '',
					method: 'GET',
				})
				await commit('SET_STATUS', '')
				if (resp.status == 200) {
					// seta os dados no store
					await commit('SET_CIDADE', resp.data.dados)
				}
				return resp.data.sucesso ? resp.data.dados : [];
			}
		},
		async getCep({ commit }, cep) {
			await commit('SET_STATUS', 'loading')
			if(cep){
				const resp = await Request({
					route: 'api/get_cep?cep=' + (cep ? cep : null) + '',
					method: 'GET',
				})
				await commit('SET_STATUS', '')
				return resp.data;
			}
		},
		async getEstado({ commit }) {
			await commit('SET_STATUS', 'loading')
			
			const resp = await Request({
				route: 'api/get_estado',
				method: 'GET',
			})
            await commit('SET_STATUS', '')
            if (resp.status == 200) {
				// seta os dados no store
				await commit('SET_ESTADO', resp.data.dados)
			}
			return resp.data.sucesso ? resp.data.dados : [];
		},
        async salvaCliente({ commit }, dados_cliente){
            await commit('SET_STATUS', 'loading')
            const resp = await Request({
                route: 'api/create_usuario',
                body: dados_cliente,
                method: 'POST',
            })
            await commit('SET_STATUS', '')
            if(resp.status == 200){
                await commit('SET_NOVO_CLIENTE', resp.data.dados)
            }
            return resp.data;
        },
        async adicionaEndereco({ commit }, dados_cliente){
            await commit('SET_STATUS', 'loading')
            const resp = await Request({
                route: 'api/adiciona_endereco',
                body: dados_cliente,
                method: 'POST',
            })
            await commit('SET_STATUS', '')
            if(resp.status == 200){
							await commit('SET_ENDERECO', resp.data.dados.usuario.enderecos)
            }
            return resp.data;
        },		
		async getEndereco({ commit }, dados) {
			await commit('SET_STATUS', 'loading')

			const resp = await Request({
				route: 'api/get_endereco?codigo_endereco=' + dados.codigo_endereco + '&usuario_id=' + dados.usuario_id,
				method: 'GET',
			})
            await commit('SET_STATUS', '')
            if (resp.status == 200) {
				// seta os dados no store
				await commit('SET_ENDERECO', resp.data.dados.usuario.enderecos)
			}
			return resp.data.sucesso ? resp : [];
		},
		async RemoverEndereco({ commit }, dados) {
			await commit('SET_STATUS', 'loading')

			const resp = await Request({
				route: 'api/remover_endereco',
				body: dados,
				method: 'POST',
			})
            await commit('SET_STATUS', '')
            if (resp.status == 200) {
				// seta os dados no store
				await commit('SET_ENDERECO', resp.data.dados.usuario.enderecos)
			}
			return resp.data.sucesso ? resp : [];
		},
		async salvaEnderecoEditado({ commit }, dados) {
			await commit('SET_STATUS', 'loading')

			const resp = await Request({
				route: 'api/salva_endereco',
				body: dados,
				method: 'POST',
			})
            await commit('SET_STATUS', '')
            if (resp.status == 200) {
				// seta os dados no store
				await commit('SET_ENDERECO', resp.data.dados.usuario.enderecos)
			}
			return resp.data.sucesso ? resp : [];
		},
		async verificaCliente({ commit }){
			if (this.getters.isLoggedIn) {
				await commit('SET_STATUS', 'loading')
				const resp = await Request({
					route: 'api/getDadosUsuario',
					method: 'GET',
				})
				await commit('SET_STATUS', '')
				if(resp.status == 200){
					await commit('SET_NOVO_CLIENTE', resp.data.dados)
				}
				return resp.data;
			}else{
				return null;
			}
		},
		async inserePedido({ commit }, pedido_){
			await commit('SET_STATUS', 'loading')
            const resp = await Request({
                route: 'api/insert_pedido',
				body: pedido_,
                method: 'POST',
            })
            await commit('SET_STATUS', '')
            if(resp.status == 200){
                await commit('SET_PEDIDO', resp.data.dados)
            }
            return resp.data;
		},
		async inserePedidoMesa({ commit }, pedido_){
			await commit('SET_STATUS', 'loading')
            const resp = await Request({
                route: 'api/insertPedidoMesa',
				body: pedido_,
                method: 'POST',
            })
            await commit('SET_STATUS', '')
            if(resp.status == 200){
                await commit('SET_PEDIDO', resp.data.dados)
            }
            return resp.data;
		},
		async solicitaConta({ commit }, pedido_id){
			
			await commit('SET_STATUS', 'loading')
            const resp = await Request({
                route: 'api/solicitaConta',
				body: {'pedido_id': pedido_id},
                method: 'POST',
            })
            await commit('SET_STATUS', '')
            return resp.data;
		},
		async verificaConta({ commit }, pedido_id){
			
			await commit('SET_STATUS', 'loading')
            const resp = await Request({
                route: 'api/verificaConta',
				body: {'pedido_id': pedido_id},
                method: 'POST',
            })
            await commit('SET_STATUS', '')
            return resp.data;
		},
		async getMeusPedidos({ commit }){
			await commit('SET_STATUS', 'loading')
            const resp = await Request({
                route: 'api/getMeusPedidos',
                method: 'GET',
            })
            await commit('SET_STATUS', '')
            
            return resp.data;
		},
		async getStatusPedido({ commit }, identificador_pedido){
			await commit('SET_STATUS', 'loading')
            const resp = await Request({
                route: 'api/getStatusPedido?id=' + identificador_pedido,
                method: 'GET',
            })
            await commit('SET_STATUS', '')
            
            return resp.data;
		},
		async verificaValorEntrega({commit}, {codigo_endereco, codigo_empresa}){
			await commit('SET_STATUS', 'loading')

			const resp = await Request({
				route: 'api/getFrete',
				body: {codigo_empresa, codigo_endereco},
				method: 'POST',
			})
            await commit('SET_STATUS', '')
            if (resp.status == 200) {
				// seta os dados no store
				await commit('SET_CIDADE', resp.data.dados)
			}
			return resp.data.sucesso ? resp.data.dados : [];
		}
	},
	getters: {
		cidades: state => state.cidades,
		clientes: state => state.clientes
	}
}