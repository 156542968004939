import Vue from 'vue'
import Vuex from 'vuex'

import config from "@/store/config.js"
import cardapio from "@/store/cardapio.js"
import cliente from "@/store/cliente.js"
import login from "@/store/login.js"
import usuarios from "@/store/usuarios.js"
import empresas from "@/store/empresas.js"
import grupos from "@/store/grupos.js"
import menus from "@/store/menus.js"
import crud_dinamico from "@/store/crud_dinamico.js"
import carrinho from "@/store/carrinho.js"
import produtos from './produtos'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		config,
		cliente,
		cardapio,
		produtos,
		login,
		usuarios,
		empresas,
		grupos,
		menus,
		crud_dinamico,
		carrinho,
	},
})