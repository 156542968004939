// importa o plugin de Request para fazer as requisições para o back
import Request from '@/plugins/request'
// importa o jwt para decodificação do token
// import jwt_decode from "jwt-decode"

var jwt = require('jsonwebtoken');

export default {
	state: {
		status: null,
        cardapio: [],
        empresa_cardapio:{},
        mesa_id:{},
				empresa_forma_calculo_id: null
	},
	mutations: {
		SET_STATUS(state, status) {
			state.status = status
		},
		SET_CARDAPIO(state, cardapio) {
			state.cardapio = cardapio
		},
		SET_EMPRESA_CARDAPIO(state, empresa_cardapio) {
			state.empresa_cardapio = empresa_cardapio
		},
		SET_MESA(state, mesa_id) {
			localStorage.setItem('mesa_id',JSON.stringify(mesa_id));
			state.mesa_id = mesa_id
		},
		SAIR_MESA(state) {
			state.mesa_id = null;
			localStorage.removeItem("mesa_id");
		},
		SET_FORMA_CALCULO(state, empresa_forma_calculo_id) {
			state.empresa_forma_calculo_id = empresa_forma_calculo_id
		},
	},
	actions: {
		async getCardapio({ commit },{codigo_empresa_servico,tipo,codigo_empresa_id}) {
			await commit('SET_STATUS', 'loading')
			const resp = await Request({
				route: 'api/getCardapio',
                body:{
                    codigo_empresa_servico:codigo_empresa_servico,
                    codigo_empresa_id:codigo_empresa_id,
                    tipo:tipo
                },
				method: 'POST',
			})
			if(resp.data.sucesso){
				for(let i = 0; i < resp.data.dados.length; i++){
					if(resp.data.dados[i].cardapio == null){
						(resp.data.dados).splice(i, 1)
					}
				}
				await commit('SET_CARDAPIO', resp.data.dados)
			}
            await commit('SET_STATUS', '')			
			return resp.data;
		},
		async verificaEmpresa({ commit },url_amigavel) {
			await commit('SET_STATUS', 'loading')

			const resp = await Request({
				route: 'api/verificaEmpresa',
                body:{
                    url_amigavel:url_amigavel
                },
				method: 'POST',
			})
			let dados = {};
			try {
				await commit('SET_FORMA_CALCULO', resp.data.empresa_forma_calculo_id)
				dados = jwt.verify(resp.data.dados,process.env.VUE_APP_SECRET)
			} catch (error) {
				dados = {}
			}

            await commit('SET_STATUS', '')
			await commit('SET_EMPRESA_CARDAPIO', dados)
			return dados;
		},
		async verificaMesa({ commit },{codigo_empresa_id, mesa_id}) {
			await commit('SET_STATUS', 'loading')
			
			const resp = await Request({
				route: 'api/mesa/verificaMesa',
                body:{
                    codigo_empresa_id: codigo_empresa_id,
					mesa_id: mesa_id
                },
				method: 'POST',
			})
			
			if(resp.data.sucesso){
				await commit('SET_MESA', mesa_id)
			}
			await commit('SET_STATUS', '')
			return resp;
		},
		async sairMesa({ commit }) {
			await commit('SET_STATUS', 'loading')
			await commit('SAIR_MESA')
			await commit('SET_STATUS', null)
			return true
		},
	},
	getters: {
		status_cardapio: state => state.status,
		cardapio: state => state.cardapio,
		empresa_cardapio: state => state.empresa_cardapio,
		mesa_id: state => state.mesa_id,
		item_cardapio: (state) => async ({categoria, id, tipo_produto}) => {
			let categorias = await state.cardapio.find(cat => cat.id == categoria)
			return JSON.parse(JSON.stringify(categorias.cardapio.find(item => (item.id == id && tipo_produto == "normal") || (item.codigo_tamanho == id && tipo_produto == "pizza"))))
			
		},
		empresa_forma_calculo_id: state => state.empresa_forma_calculo_id,
	}
}