import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import vuetify from '@/plugins/vuetify'
import VueTheMask from 'vue-the-mask'
import DatetimePicker from 'vuetify-datetime-picker'
import money from 'v-money'
import VueSweetalert2 from 'vue-sweetalert2';
import VueNumericInput from 'vue-numeric-input';

Vue.use(VueTheMask)
Vue.use(DatetimePicker)
Vue.use(money, { precision: 2 })
Vue.use(VueSweetalert2);
Vue.use(VueNumericInput)

import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false

Vue.prototype.moment = moment

moment.locale('pt-br')

new Vue({
	router,
	vuetify,
	store,
	render: h => h(App)
}).$mount('#app')
