// importa o plugin de Request para fazer as requisições para o back
import Request from '@/plugins/request'

// eporta o store de usuários
export default {
	// estado do store de usuários
	state: {
		// status do store de usuários
		status: null,
		// registro dos usuários
		empresas: null,
	},
	// funções para modificar o estado do store de usuários
	mutations: {
		// função para setar o status do store de usuários
		SET_STATUS(state, status) {
			// seta o status do store de usuários
			state.status = status
		},
		// função para setar os registros de usuários
		SET_EMPRESA(state, empresas) {
			// seta os registros de usuários
			state.empresas = {
				// seta os registros de usuários
				dados: empresas.dados.data,
				// seta o total registros de usuários
				total: empresas.dados.total
			}
		},
	},
	// funções para as ações do store de usuários
	actions: {
		/**
		 * função para coletar os registros dos usuários do back
		 * @param {*} param0 
		 * @param {*} filters 
		 */
		async getEmpresas({ commit }, filters) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/empresa/get_empresas?' +
					(filters.offset ? '&offset=' + filters.offset : '') +
					// quantos registros devem ser retornados
					(filters.limit ? '&limit=' + filters.limit : '') +
					// por qual coluna devem ser ordenados os registros
					(filters.order_column ? '&order_column=' + filters.order_column : '&order_column=nome') +
					// qual a ordem que deve ser ordenado (ASC ou DESC)
					(filters.order_dir ? '&order_dir=' + filters.order_dir : '&order_column=asc') +
					// os registros que contem a busca solicitada
					(filters.search ? '&search=' + filters.search : '') + 
					// os registros que contem a busca solicitada
					(filters.currentPage ? '&page=' + filters.currentPage : ''),
			})
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// caso o status seja 200 (requisiçao foi concluida) 
			if (resp.status == 200) {
				// seta os dados no store
				await commit('SET_EMPRESA', resp.data)
			}
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para coletar os registros dos usuários do back
		 * @param {*} param0 
		 * @param {*} filters 
		 */
		async getEmpresasServicos({ commit }, filters) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/empresa/get_empresas_servicos?' +
					(filters.offset ? '&offset=' + filters.offset : '') +
					// quantos registros devem ser retornados
					(filters.limit ? '&limit=' + filters.limit : '') +
					// por qual coluna devem ser ordenados os registros
					(filters.order_column ? '&order_column=' + filters.order_column : '&order_column=nome') +
					// qual a ordem que deve ser ordenado (ASC ou DESC)
					(filters.order_dir ? '&order_dir=' + filters.order_dir : '&order_column=asc') +
					// os registros que contem a busca solicitada
					(filters.search ? '&search=' + filters.search : '') + 
					// os registros que contem a busca solicitada
					(filters.currentPage ? '&page=' + filters.currentPage : ''),
			})
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// caso o status seja 200 (requisiçao foi concluida) 
			if (resp.status == 200) {
				// seta os dados no store
				await commit('SET_EMPRESA', resp.data)
			}
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para coletar apenas 1 usuário do back
		 * @param {*} param0 
		 * @param {*} empresa_id 
		 */
		async getEmpresa({ commit }, empresa_id) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/empresa/get_empresa/?codigo=' + empresa_id,
			})
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para coletar dados do cep informado
		 * @param {*} param0 
		 * @param {*} valor 
		 */
		async buscaCep({ commit }, cep_busca) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/empresa/get_cep/?codigo=' + cep_busca,
			})
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para coletar apenas 1 usuário do back
		 * @param {*} param0
		 * @param {*} empresa_id
		 */
		async getEmpresaServico({ commit }, empresa_id) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/empresa/get_empresa_servico/?codigo=' + empresa_id,
			})
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 * @param {*} dados_empresa 
		 */
		async postEmpresas({ commit }, dados_empresa) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/empresa/post_empresa/',
				// corpo da requisição
				body: dados_empresa,
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 * @param {*} dados_empresa 
		 */
		async postEmpresasServicos({ commit }, dados_empresa) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/empresa/post_empresa_servicos/',
				// corpo da requisição
				body: dados_empresa,
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 * @param {*} dados_empresa 
		 */
		async getEmpresaSelect({ commit }) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/empresa/get_empresa_select',
				method: 'GET'
			})
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 * @param {*} dados_empresa 
		 */
		async getServicoSelect({ commit }) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/empresa/get_servico_select',
				method: 'GET'
			})
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 * @param {*} dados_frete
		 */
		 async adicionaFrete({ commit }, dados_frete) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/empresa/post_frete/',
				// corpo da requisição
				body: dados_frete,
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})
			// seta o status do store de empresa para vazio
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 * @param {*} empresa_url_amigavel
		 */
		 async getFormasPagamento({ commit }, empresa_url_amigavel) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/empresa/getFormasPagamento/?empresa_url_amigavel=' + empresa_url_amigavel,
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'GET'
			})
			// seta o status do store de empresa para vazio
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
		/**
		 * função para coletar os bancos disponíveis
		 * @param {*} param0 
		 * @param {*} empresa_id
		 * @returns 
		 */
		async getBancos({ commit }, empresa_id){
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variável resp
			const resp = await Request({
				// url da rota para fazer a requisição
				route: 'api/financeiro/getBancos/' + empresa_id,
				// método da requição
				method: 'GET'
			})
			// seta o status do store de empresa para vazio
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
		/**
		 * função para coletar os dados da empresa do financeiro
		 * @param {*} param0 
		 * @param {*} empresa_id
		 * @returns 
		 */
		async getDadosFinanceiros({ commit }, empresa_id){
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variável resp
			const resp = await Request({
				// url da rota para fazer a requisição
				route: 'api/financeiro/getDadosFinanceiro/' + empresa_id,
				// método da requição
				method: 'GET'
			})
			// seta o status do store de empresa para vazio
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
		/**
		 * função para cadastrar a empresa no sistema do financeiro
		 * @param {*} param0 
		 * @param {*} dados_empresa 
		 */
		async postEmpresaFinanceiro({ commit }, dados_empresa){
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'api/financeiro/postEmpresa/',
				// corpo da requisição
				body: dados_empresa,
				// método da requisição
				method: 'POST'
			})
			// seta o status do store de empresas para vazio
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
		/**
		 * função para atualizar uma empresa no sistema do financeiro
		 * @param {*} param0 
		 * @param {*} date_update 
		 */
		async putEmpresaFinanceiro({ commit }, date_update){
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'api/financeiro/putEmpresa/' + date_update.id,
				// corpo da requisição
				body: date_update.dados_empresa,
				// método da requição
				method: 'PUT'
			})
			// seta o status do store de empresa para vazio
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
		async getFormasCalculos({ commit }) {
			await commit('SET_STATUS', 'loading')
			
			const resp = await Request({
				route: 'admin/empresa/get_forma_calculo',
				method: 'GET',
			})
            await commit('SET_STATUS', '')
            if (resp.status == 200) {
				// seta os dados no store
				await commit('SET_ESTADO', resp.data.dados)
			}
			return resp.data.sucesso ? resp.data.dados : [];
		},
	},
	// funções para coletar o estado do store de usuários
	getters: {
		// coleta o status do store de usuários
		status_empresas: state => state.status,
		// coleta os registros do store de usuários
		empresas: state => state.empresas,
	}
}