import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

function verificaLogin(to, from, next) {
	if (store.getters.isLoggedIn) {
		store.dispatch('setUser').then(function () {
			if(store.getters.user.tipo == 'admin'){
				next()
				return
			}else{
				next('/sem-permissao')
				return
			}
		})
	} else {
		next('/login')
	}
}

function verificaRota(to, from, next) {
	if (store.getters.isLoggedIn) {
		store.dispatch('setUser').then(function () {
			let path = to.path
			if(to.meta && to.meta.path){
				path = to.meta.path
			}
			store.dispatch('verificaMenuByUsuario',path).then(function (retorno) {
				if(retorno){
					next()
					return
				}else{
					next('/sem-permissao')
					return
				}
			})	
		})
	} else {
		next('/login')
	}
}

async function verificaCliente(to,next){
	
	await store.dispatch('verificaEmpresa',to.params.url_amigavel).then(async function (retorno) {
		if(retorno.nome != undefined){
			await store.dispatch('verificaCliente');
			next()
			return
		}else{
			next('/')
			return
		}
	})
}


async function verificaClienteLogado(to,next){
	if (store.getters.isLoggedIn) {
		await store.dispatch('verificaCliente');
		await store.dispatch('verificaEmpresa',to.params.url_amigavel).then(function (retorno) {
			if(retorno.nome != undefined){
				next()
				return
			}else{
				next('/')
				return
			}
		})
	} else {
		next('/'+to.params.url_amigavel+'/login')
	}
}


function notLogin(to, from, next) {
	if (!store.getters.isLoggedIn) {
		next()
		return
	} else {
		next('/admin')
	}
}

function notLoginRecover(to, from, next) {
	if (!store.getters.isLoggedIn && localStorage.getItem('recover_token')) {
		next()
		return
	} else {
		next('/recuperar-senha')
	}
}

function deslogar(to, from, next) {
	store.dispatch('logout').then(function () {
		next('/login')
		return
	})
}

function deslogarCliente(to, from, next){
	store.dispatch('logout').then(function () {
		next('/' + to.params.url_amigavel + '/login')
		return
	})
}

const routes = [
	{
		path: '/admin',
		name: 'Dashboard',
		component: () => import('@/views/Dashboard.vue'),
		beforeEnter(to, from, next) {
			verificaLogin(to, from, next)
		},
		children: [
			{
				path: '/admin/usuarios',
				name: 'Usuários',
				beforeEnter(to, from, next) {
					verificaRota(to, from, next)
				},
				component: () => import('@/views/pages/admin/UsuariosAdmin.vue'),
			},
			{
				path: '/admin/grupos',
				name: 'Grupos',
				beforeEnter(to, from, next) {
					verificaRota(to, from, next)
				},
				component: () => import('@/views/pages/Grupos.vue'),
			},
			{
				path: '/admin/perfil',
				name: 'Perfil',
				// beforeEnter(to, from, next) {
				// 	verificaRota(to, from, next)
				// },
				component: () => import('@/views/pages/admin/PerfilAdmin.vue'),
			},
			{
				path: '/admin/empresas',
				name: 'Empresas',
				beforeEnter(to, from, next) {
					verificaRota(to, from, next)
				},
				component: () => import('@/views/pages/admin/EmpresasAdmin.vue'),
			},
			{
				path: '/admin/empresas-servicos',
				name: 'EmpresasServiços',
				beforeEnter(to, from, next) {
					verificaRota(to, from, next)
				},
				component: () => import('@/views/pages/admin/EmpresasServicosAdmin.vue'),
			},
			{
				path: '/admin/crud_dinamico/:crud',
				name: 'Crud Dinamico',
				beforeEnter(to, from, next) {
					verificaRota(to, from, next)
				},
				component: () => import('@/views/pages/CrudDinamico.vue'),
			},
			{
				path: '/admin/produtos',
				name: 'Produtos',
				beforeEnter(to, from, next) {
					verificaRota(to, from, next)
				},
				component: () => import('@/views/pages/admin/Produtos.vue'),
			},
			{
				path: '/admin/produtos/new-product/pizza',
				name: 'Produtos',
				meta:{
					"type":'pizza'
				},
				beforeEnter(to, from, next) {
					verificaRota(to, from, next)
				},
				component: () => import('@/views/pages/admin/product/NewProduct.vue'),
			},
			{
				path: '/admin/produtos/new-product/normal',
				name: 'Produtos',
				meta:{
					"type":'normal'
				},
				beforeEnter(to, from, next) {
					verificaRota(to, from, next)
				},
				component: () => import('@/views/pages/admin/product/NewProduct.vue'),
			},
			{
				path: '/admin/produtos/edit-product/:produto_id',
				name: 'Produtos',
				meta:{
					"path":'/admin/produtos/edit-product/:produto_id'
				},
				beforeEnter(to, from, next) {
					verificaRota(to, from, next)
				},
				component: () => import('@/views/pages/admin/product/NewProduct.vue'),
			},
		]
	},
	{
		path: '/admin/portal',
		name: 'Pedidos',
		beforeEnter(to, from, next) {
			verificaRota(to, from, next)
		},
		component: () => import('@/views/pages/admin/PortalAdmin.vue'),
	},
	
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/login/Login.vue'),
		beforeEnter(to, from, next) {
			notLogin(to, from, next)
		}
	},
	{
		path: '/logout',
		beforeEnter(to, from, next) {
			deslogar(to, from, next)
		}
	},
	{
		path: '/:url_amigavel',
		name: 'Cliente',
		component: () => import('@/views/pages/cliente/Dashboard.vue'),
		beforeEnter(to, from, next) {
			verificaCliente(to, next)
		},
		children: [
			{
				path: '/:url_amigavel/',
				name: 'Cardapio',
				component: () => import('@/views/pages/cardapio/Cardapio.vue'),
				beforeEnter(to, from, next) {
					verificaCliente(to, next)
				},
			},
			{
				path: '/:url_amigavel?mesa=:mesa_id',
				name: 'Cardapio Mesa',
				component: () => import('@/views/pages/cardapio/Cardapio.vue'),
				beforeEnter(to, from, next) {
					verificaCliente(to, next)
				},
			},
			{
				path: '/:url_amigavel/login',
				name: 'LoginCliente',
				component: () => import('@/views/pages/cliente/LoginCliente.vue'),
				beforeEnter(to, from, next) {
					verificaCliente(to, next)
				},
			},
			{
				path: '/:url_amigavel/perfil',
				name: 'EditarInformacoes',
				component: () => import('@/views/pages/cliente/EditarInformacoes.vue'),
				beforeEnter(to, from, next) {
					verificaClienteLogado(to, next)
				},
			},
			{
				path: '/:url_amigavel/historico-de-pedidos',
				name: 'HistoricoPedidos',
				component: () => import('@/views/pages/cliente/HistoricoPedidos.vue'),
				beforeEnter(to, from, next) {
					verificaClienteLogado(to, next)
				},
			},
			{
				path: '/:url_amigavel/alterar-email',
				name: 'AlterarEmail',
				component: () => import('@/views/pages/cliente/AlterarEmail.vue'),
				beforeEnter(to, from, next) {
					verificaClienteLogado(to, next)
				},
			},
			{
				path: '/:url_amigavel/alterar-senha',
				name: 'AlterarSenha',
				component: () => import('@/views/pages/cliente/AlterarSenha.vue'),
				beforeEnter(to, from, next) {
					verificaClienteLogado(to, next)
				},
			},
			{
				path: '/:url_amigavel/home',
				name: 'HomeCardapio',
				component: () => import('@/views/pages/cliente/HomeCardapio.vue'),
				beforeEnter(to, from, next) {
					verificaCliente(to, next)
				},
			},
			{
				path: '/:url_amigavel/finalizar',
				name: 'FinalizarCompra',
				component: () => import('@/views/pages/FinalizarCompra.vue'),
				beforeEnter(to, from, next) {
					verificaClienteLogado(to, next)
				},
			},
			{
				path: '/:url_amigavel/logout',
				beforeEnter(to, from, next) {
					deslogarCliente(to, from, next)
				}
			},
			{
				path: '/:url_amigavel/meus-pedidos/:identificador_pedido',
				name: 'Status do pedido',
				component: () => import('@/views/pages/StatusPedido.vue'),
				beforeEnter(to, from, next) {
					verificaClienteLogado(to, next)
				},
			},
		]
	},
	{
		path: '/recuperar-senha',
		name: 'Esqueci minha senha',
		component: () => import('@/views/login/RecuperarSenha.vue'),
		beforeEnter(to, from, next) {
			notLogin(to, from, next)
		}
	},
	{
		path: '/validar-codigo',
		name: 'Validar código',
		component: () => import('@/views/login/RecuperarSenhaCodigo.vue'),
		beforeEnter(to, from, next) {
			notLoginRecover(to, from, next)
		}
	},
	{
		path: '/nova-senha',
		name: 'Nova senha',
		component: () => import('@/views/login/NovaSenha.vue'),
		beforeEnter(to, from, next) {
			notLoginRecover(to, from, next)
		}
	},
	{
		path: '/:url_amigavel/privacidade',
		name: 'Políticas de privacidade',
		component: () => import('@/views/PoliticasDePrivacidade.vue'),
	},
	{
		path: '/sem-permissao',
		name: 'Sem Permissão',
		component: () => import('@/views/pages/SemPermissao.vue'),
	},
	{
		path: '*',
		name: '404',
		component: () => import('@/views/pages/404.vue'),
	},
]

export default new VueRouter({
	routes,
	linkExactActiveClass: 'active',
	mode: 'history'
})