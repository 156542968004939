// importa o plugin de Request para fazer as requisições para o back
import Request from '@/plugins/request'
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

export default {
	state: {
		status: null,
        produtos: [],
        categorias: [],
		bootstrap:bootstrap
	},
	mutations: {
		SET_STATUS(state, status) {
			state.status = status
		},
		SET_PRODUTOS(state, produtos) {
			state.produtos = produtos
		},
		SET_CATEGORIAS(state, categorias) {
			state.categorias = categorias
		},
	},
	actions: {
		async getProdutos({ commit }, filters) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/produto?' +
					(filters['offset'] ? '&offset=' + filters['offset'] : '') +
					// quantos registros devem ser retornados
					(filters['limit'] ? '&limit=' + filters['limit'] : '') +
					// por qual coluna devem ser ordenados os registros
					(filters['order_column'] ? '&order_column=' + filters['order_column'] : '&order_column=nome_produto') +
					// qual a ordem que deve ser ordenado (ASC ou DESC)
					(filters['order_dir'] ? '&order_dir=' + filters['order_dir'] : '&order_column=asc') +
					// os registros que contem a busca solicitada
					(filters['search'] ? '&search=' + filters['search'] : '') + 
					// os registros que contem a busca solicitada
					(filters['currentPage'] ? '&page=' + filters['currentPage'] : ''),
			})
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// caso o status seja 200 (requisiçao foi concluida) 
			if (resp.status == 200) {
				// seta os dados no store
				await commit('SET_PRODUTOS', resp.data)
			}
			// retorna a resposta da requisição
			return resp
		},


		/**
		 * função para coletar apenas 1 usuário do back
		 * @param {*} param0 
		 * @param {*} codigo 
		 */
		async getCategoriaSelect({ commit }) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/produto/categoria', 
			})
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')

            // seta os dados no store
            await commit('SET_CATEGORIAS', resp.data)
			// retorna a resposta da requisição
			return resp
		},


		/**
		 * função para coletar apenas 1 usuário do back
		 * @param {*} param0 
		 * @param {*} codigo 
		 */
		async getProduto({ commit },produto_id) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/produto/' + produto_id, 
			})
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')

            // seta os dados no store
			return resp
		},


		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 * @param {*} produto 
		 */
		async postProdutos({ commit }, produto) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/produto/',
				// corpo da requisição
				body: produto,
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
	},
	getters: {
		status_produtos: state => state.status,
		produtos: state => state.produtos,
	}
}