// importa o plugin de Request para fazer as requisições para o back
import Request from '@/plugins/request'
// importa o jwt para decodificação do token
// import jwt_decode from "jwt-decode"

export default {
	state: {
		status: null,
		token: localStorage.getItem('token') || null,
		user: null,
		recorever_email: null,
		menus_usuario:[]
	},
	mutations: {
		SET_STATUS(state, status) {
			state.status = status
		},
		SET_TOKEN(state, token) {
			state.token = token
		},
		SET_USER(state, user) {
			state.user = user
		},
		SET_MENUS_USUARIO(state, menus_usuario) {
			state.menus_usuario = menus_usuario
		},
		SET_RECOVER_EMAIL(state, email) {
			state.recorever_email = email
		},
		LOGOUT(state) {
			state.status = null
			state.token = null
			state.user = null
		},
	},
	actions: {

		async login({ commit, dispatch }, user) {
			await commit('SET_STATUS', 'loading')

			const resp = await Request({
				route: 'admin/login',
				body: {
					email: user.usuario,
					senha: user.senha,
					form: user.form,
				},
				method: 'POST',
			})
			
			if (resp.data.sucesso) {
				if (user.lembrar_usuario) {
					localStorage.setItem('usuario', user.usuario)
					localStorage.setItem('senha', user.senha)
					localStorage.setItem('lembrar_usuario', user.lembrar_usuario)
				} else {
					localStorage.removeItem('usuario')
					localStorage.removeItem('senha')
					localStorage.removeItem('lembrar_usuario')
				}

				await localStorage.setItem('token', resp.data.dados.token)

				await commit('SET_TOKEN', resp.data.dados.token)

				await dispatch('setUser')

			} else {
				await commit('SET_STATUS', 'error')
				await localStorage.removeItem('token')
			}

			return resp;
		},

		async setUser({ commit }) {
			
			await commit('SET_STATUS', 'loading')
			
			if (this.getters.token){
				var dados_usuario = {};

				const resp = await Request({
					route: 'admin/usuario/getDadosUsuario'
				})

				dados_usuario = resp.data.sucesso ? resp.data.dados : {}

				await commit('SET_USER',dados_usuario['usuario'] != undefined ? dados_usuario['usuario'] : null)

				await commit('SET_MENUS_USUARIO', resp.data.sucesso ? resp.data.dados.menus : [])
				return dados_usuario
			}
			
			return false
		},

		async logout({ commit }) {
			console.log('deslogar')
			await commit('LOGOUT')
			await localStorage.removeItem('token')
			return
		},

		async recuperarSenha({ commit }, inputs) {
			await commit('SET_STATUS', 'loading')
			const resp = await Request({
				route: '/usuario/recuperarSenha',
				body: {
					email: inputs.email
				},
				method: 'POST',
			})
		
			if (resp.data.sucesso){
				await commit('SET_RECOVER_EMAIL', resp.data.dados.email)
				await localStorage.setItem('recorever_email', resp.data.dados.email)
			}else{
				await localStorage.removeItem('recorever_email')
				await await commit('SET_STATUS', 'error')
				await commit('SET_RECOVER_EMAIL', '')
			}
			return resp
		},

		async recuperarSenhaValidarCodigo({ commit }, inputs) {
			await commit('SET_STATUS', 'loading')
		
			const resp = await Request({
				route: '/usuario/recuperarSenha/codigo',
				body: {
					codigo: inputs.codigo,
					email: localStorage.getItem('recorever_email'),
				},
				method: 'POST',
			})

			if (resp.data.sucesso) {
				await localStorage.setItem('recover_token', resp.data.dados.token)
			} else {
				await await commit('SET_STATUS', 'error')
				await commit('SET_RECOVER_EMAIL', '')
				await localStorage.removeItem('recorever_email')
				await localStorage.removeItem('recover_token')
			}

			return resp
		},

		async recuperarSenhaNovaSenha({ commit, dispatch }, inputs) {
				
			// axios.defaults.headers.common['Authorization'] = await localStorage.getItem('recover_token')
			
			await commit('SET_STATUS', 'loading')
			
			const resp = Request({
				route: '/usuario/recuperarSenha/nova',
				body: {
					senha: inputs.senha,
					repeteSenha: inputs.repeteSenha,
				},
				method: 'POST',
			})
			
			if (resp.data.sucesso) {
				const resp_login = await dispatch('login', {
					usuario: localStorage.getItem('recorever_email'),
					senha: inputs.senha
				})
				
				if (resp_login.data.sucesso) {
					await localStorage.removeItem('recorever_email')
					await localStorage.removeItem('recover_token')
				}
			}else{
				await await commit('SET_STATUS', 'error')
				await commit('SET_RECOVER_EMAIL', '')
				await localStorage.removeItem('recorever_email')
				await localStorage.removeItem('recover_token')
			}

			return resp
		},
	},
	getters: {
		isLoggedIn: state => !!state.token,
		authStatus: state => state.status,
		token: state => state.token,
		user: state => state.user,
		menus_usuario: state => state.menus_usuario,
		recorever_email: state => state.recorever_email,
	}
}