// importa o plugin de Request para fazer as requisições para o back
import Request from '@/plugins/request'

// eporta o store de menus
export default {
	// estado do store de menus
	state: {
		// status do store de menus
		status: null,
		// registro dos menus
		menus: null,
		// registro dos menus
		menus_select: null,
	},
	// funções para modificar o estado do store de menus
	mutations: {
		// função para setar o status do store de menus
		SET_STATUS(state, status) {
			// seta o status do store de menus
			state.status = status
		},
		// função para setar os registros de menus
		SET_MENUS(state, menus) {
			// seta os registros de menus
			state.menus = {
				// seta os registros de menus
				dados: menus.dados,
				// seta o total registros de menus
				total: menus.count || menus.dados.length 
			}
		},
		// função para setar os registros de menus
		SET_MENUS_SELECT(state, menus_select) {
			// seta os registros de menus
			state.menus_select = menus_select
		},
	},
	// funções para as ações do store de menus
	actions: {
		/**
		 * função para coletar os registros dos menus do back
		 * @param {*} param0 
		 * @param {*} filters 
		 */
		async getMenus({ commit }, filters) {
			// seta o status do store de menus para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/menu/get_menus?' +
					// quantos registros devem ser pulados
					(filters.offset ? '&offset=' + filters.offset : '') +
					// quantos registros devem ser retornados
					(filters.limit ? '&limit=' + filters.limit : '') +
					// por qual coluna devem ser ordenados os registros
					(filters.order_column ? '&order_column=' + filters.order_column : '&order_column=nome') +
					// qual a ordem que deve ser ordenado (ASC ou DESC)
					(filters.order_dir ? '&order_dir=' + filters.order_dir : '&order_column=asc') +
					// os registros que contem a busca solicitada
					(filters.search ? '&search=' + filters.search : ''), 
			})
			// seta o status do store de menu para vazio 
			await commit('SET_STATUS', '')
			// caso o status seja 200 (requisiçao foi concluida) 
			if (resp.status == 200) {
				// seta os dados no store
				await commit('SET_MENUS', resp.data)
			}
			// retorna a resposta da requisição
			return resp
		},
		/**
		 * função para coletar os registros dos menus do back
		 * @param {*} param0 
		 * @param {*} filters 
		 */
		async getMenusSelect({ commit }) {
			// seta o status do store de menus para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/menu/get_menu_select'
			})
			// seta o status do store de menu para vazio 
			await commit('SET_STATUS', '')
			// caso o status seja 200 (requisiçao foi concluida) 
			if (resp.status == 200) {
				// seta os dados no store
				await commit('SET_MENUS', resp.data)
			}
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para coletar apenas 1 usuário do back
		 * @param {*} param0 
		 * @param {*} menu_id 
		 */
		async getMenu({ commit }, menu_id) {
			// seta o status do store de menus para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/menu/get_menu/?menu_id=' + menu_id, 
			})
			// seta o status do store de menu para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
		
		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 * @param {*} dados_menu 
		 */
		async postMenu({ commit }, dados_menu) {
			// seta o status do store de menus para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'admin/menu/post_menu/',
				// corpo da requisição
				body: dados_menu, 
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})
			// seta o status do store de menu para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
	},
	// funções para coletar o estado do store de menus
	getters: {
		// coleta o status do store de menus
		status_menus: state => state.status,
		// coleta os registros do store de menus
		menus: state => state.menus,
		// coleta os registros do store de menus
		menus_select: state => state.menus_select,
	}
}