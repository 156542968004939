import _ from 'lodash';
import Request from '@/plugins/request';

export default {
	state: {
		status: null,
		carrinho: JSON.parse(localStorage.getItem("pedidoCarrinho")) || [],
		conta: JSON.parse(localStorage.getItem("contaPendente")) || [],
		pedido_mesa_id: null,
		totalPedido: 0,
		totalDeItens: 0,
	},
	mutations: {
		SET_STATUS(state, status) {
			state.status = status
		},
		SET_ITEM_CARRINHO(state, item) {
			let existe = false

			for (let index = 0; index < state.carrinho.length; index++) {
				const element = state.carrinho[index];
				if(element.id == item.id){
					if (_.isEqual(element.grupo_complementos, item.grupo_complementos)) {
						state.carrinho[index].quantidade++
						existe=true;
					}
					if (_.isEqual(element.borda, item.borda) && _.isEqual(element.massa, item.massa) && _.isEqual(element.sabores, item.grupo_complementos)) {
						state.carrinho[index].quantidade++
						existe = true;
					}
				}
			}

			if(!existe){
				state.carrinho.push(item)
			}

			localStorage.setItem("pedidoCarrinho", JSON.stringify(state.carrinho));
		},
		SET_CONTA(state, pedido) {
			
			if(pedido.pedido_mesa_id){
				localStorage.setItem('pedido_mesa_id',JSON.stringify(pedido.pedido_mesa_id));
				state.pedido_mesa_id = pedido.pedido_mesa_id
			}
			pedido = Array.from(pedido)
			for (let index = 0; index < pedido.length; index++) {				
				let existe = false
				const element = state.conta;
				if(state.conta == ''){					
					state.conta.push(pedido[index])
				}else{
					for(let indexConta = 0; indexConta < state.conta.length; indexConta++){
						if(element[indexConta].id == pedido[index].id){
							if (_.isEqual(element[indexConta].grupo_complementos, pedido[index].grupo_complementos)) {
								element[indexConta].quantidade += pedido[index].quantidade
								existe=true;
							}
						}
					}
					if(!existe){
						element.push(pedido[index])
					}
				}
			}
			localStorage.setItem("contaPendente", JSON.stringify(state.conta));
		},
		REMOVE_ITEM_CARRINHO(state, item) {
			for (let index = 0; index < state.carrinho.length; index++) {
				if(state.carrinho[index].id == item.id){
					if (_.isEqual(state.carrinho[index].grupo_complementos, item.grupo_complementos)) {
						state.carrinho.splice(index, 1)
					}
				}
			}
			localStorage.setItem("pedidoCarrinho", JSON.stringify(state.carrinho));
		},
		SET_QTD_ITEM_CARRINHO(state, item) {
			for (let index = 0; index < state.carrinho.length; index++) {
				if(state.carrinho[index].id == item.id){
					if (_.isEqual(state.carrinho[index].grupo_complementos, item.grupo_complementos)) {
						if(item.quantidade < 1 ){
							state.carrinho.splice(index, 1)
						}else{
							state.carrinho[index].quantidade = item.quantidade
						}
					}
				}
			}
			localStorage.setItem("pedidoCarrinho", JSON.stringify(state.carrinho));
		},
		CLEAR_CARRINHO(state) {
			state.carrinho = [];
			localStorage.removeItem("pedidoCarrinho");
		},
		CLEAR_CONTA(state) {
			state.conta = [];
			state.pedido_mesa_id = null;
			localStorage.removeItem("contaPendente");
			localStorage.removeItem("pedido_mesa_id");
		},
		SET_CARRINHO(state, carrinho) {
			state.carrinho = carrinho;
			localStorage.setItem("pedidoCarrinho", JSON.stringify(state.carrinho));
		},
	},
	actions: {
		async addItemCarrinho({ commit },{ item }) {
			await commit('SET_STATUS', 'loading')
			await commit('SET_ITEM_CARRINHO', item)
			await commit('SET_STATUS', null)
			return true;
		},
		async removeItemCarrinho({ commit },{ item }) {
			await commit('SET_STATUS', 'loading')
			await commit('REMOVE_ITEM_CARRINHO', item)
			await commit('SET_STATUS', null)
		},
		async setQtdItemCarrinho({ commit },{ item }) {
			await commit('SET_STATUS', 'loading')
			await commit('SET_QTD_ITEM_CARRINHO', item)
			await commit('SET_STATUS', null)
		},
		async clearCarrinho({ commit }) {
			await commit('SET_STATUS', 'loading')
			await commit('CLEAR_CARRINHO')
			await commit('SET_STATUS', null)
		},
		async clearConta({ commit }) {
			await commit('SET_STATUS', 'loading')
			await commit('CLEAR_CONTA')
			await commit('SET_STATUS', null)
		},
		async insereConta({ commit }, pedido){
			await commit('SET_STATUS', 'loading')
			await commit('SET_CONTA', pedido)
			await commit('SET_STATUS', null)
			return true;
		},
		async verificaItensCarrinho({ commit }, carrinho){
			console.log(carrinho)
			await commit('SET_STATUS', 'loading')
			const resp = await Request({
					route: 'api/verificaItensCarrinho',
					body: { carrinho },
					method: 'POST',
			})
			if(resp.status == 200){
				carrinho = resp.data.dados;
				await commit('SET_CARRINHO', carrinho.carrinho)
			}
			await commit('SET_STATUS', null)

			return carrinho
		},
	},
	getters: {
		status_carrinho: state => state.status,
		carrinho: state => state.carrinho,
		conta: state => state.conta,
		pedido_mesa_id: state => state.pedido_mesa_id,
		qtde_produtos_no_carrinho: state => state.carrinho.length,
		total_pedido: (state) => {
			let total_ = 0
			for (let index = 0; index < state.carrinho.length; index++) {
				let element = JSON.parse(JSON.stringify(state.carrinho[index]));
				total_ += element.preco * element.quantidade
				element.grupo_complementos.forEach(grupo_complemento => {
					grupo_complemento.complementos.forEach(complemento => {
						if(complemento.valor != null ){
							total_ += complemento.valor * complemento.quantidade
						}
					});
				});
			}
            return total_
		},
		totalDeItens: state => state.totalDeItens,
	}
}