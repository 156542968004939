<template>
	<v-app id="app">
		<router-view/>
	</v-app>
</template>
<script>
	export default {
		methods: {
			handleResize() {
				this.$store.dispatch('setScreenSize')
			}
		},
		created() {
			window.addEventListener('resize', this.handleResize)
			this.handleResize()
		},
		destroyed() {
			window.removeEventListener('resize', this.handleResize)
		},
	}
</script>
<style lang="scss">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: $primaryfontcolor;
		min-height: 100vh;
		display: flex;
		max-width: 100vw
	}
</style>